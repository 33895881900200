// import libraries
import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { Card, Container } from 'react-bootstrap';
import { FormattedMessage } from 'gatsby-plugin-intl';
import PropTypes from 'prop-types';

// import files
import * as styles from '../../../style/home-page/feature-section.module.scss';
import Text from '../../../lib/Text/Text';
import Button from '../../../lib/button/Button';
// import Animation from '../../../lib/Animation/Animation';

const COMPANY_URL = process.env.GATSBY_COMPANY_URL;

const FeatureSection = (props) => {
  const { language = 'en' } = props;

  return (
    <>
      <div className={styles.customContainer}>
        <Container className={styles.innerCustomContainer}>
          <h5 className={styles.heading}>
            <FormattedMessage id='index-page.featureSection.heading' />
          </h5>
          <Card className={styles.customCard}>
            {/* <Animation> */}
            <div className='row justify-content-center'>
              <div className='col-md-6 d-flex align-items-center'>
                <Card.Body className={styles.customBody}>
                  <Card.Title>
                    <Text title className={styles.title}>
                      <FormattedMessage id='index-page.featureSection.feature1.title' />
                    </Text>
                  </Card.Title>
                  <Card.Subtitle>
                    <Text subtitle className={styles.subtitle}>
                      <FormattedMessage id='index-page.featureSection.feature1.subTitle' />
                    </Text>
                  </Card.Subtitle>
                  <Card.Text>
                    <Text body className={styles.text}>
                      <FormattedMessage id='index-page.featureSection.feature1.text' />
                    </Text>
                  </Card.Text>
                  <Button
                    alternate
                    onClick={() => {
                      if (typeof window !== 'undefined') {
                        window.open(
                          `${COMPANY_URL}/request-demo?lang=${language}`,
                          '_blank'
                        );
                      }
                    }}
                  >
                    <FormattedMessage id='common.buttons.learnMore' />
                  </Button>
                </Card.Body>
              </div>
              <div className='col-md-1' />
              <div className='col-md-5'>
                <StaticImage
                  style={{ backgroundColor: 'transparent !important' }}
                  src='../../../images/influencer_finder.png'
                  alt='influencer_finder'
                />
              </div>
            </div>
            {/* </Animation> */}
          </Card>

          <Card className={styles.customCard}>
            {/* <Animation fadeIn='fadeInRight'> */}
            <div className='row justify-content-center'>
              <div className='col-md-5'>
                <StaticImage
                  placeholder='blurred'
                  style={{ backgroundColor: 'transparent !important' }}
                  src='../../../images/campaign_management.png'
                  alt='campaign_management'
                />
              </div>
              <div className='col-md-1' />

              <div className='col-md-6  d-flex align-items-center'>
                <Card.Body
                  className={[styles.customBody, styles.textAlignRight].join(
                    ' '
                  )}
                >
                  <Card.Title>
                    <Text
                      title
                      className={[styles.title, styles.textAlignRight].join(
                        ' '
                      )}
                    >
                      <FormattedMessage id='index-page.featureSection.feature2.title' />
                    </Text>
                  </Card.Title>
                  <Card.Subtitle className='pl-5'>
                    <Text
                      subtitle
                      className={[
                        styles.subtitle,
                        styles.textAlignRight,
                      ].join(' ')}
                    >
                      <FormattedMessage id='index-page.featureSection.feature2.subTitle' />
                    </Text>
                  </Card.Subtitle>
                  <Card.Text>
                    <Text
                      body
                      className={[styles.text, styles.textAlignRight].join(
                        ' '
                      )}
                    >
                      <FormattedMessage id='index-page.featureSection.feature2.text' />
                    </Text>
                  </Card.Text>
                  <Button
                    alternate
                    className={styles.marginRight}
                    onClick={() => {
                      if (typeof window !== 'undefined') {
                        window.open(
                          `${COMPANY_URL}/request-demo?lang=${language}`,
                          '_blank'
                        );
                      }
                    }}
                  >
                    <FormattedMessage id='common.buttons.learnMore' />
                  </Button>
                </Card.Body>
              </div>
            </div>
            {/* </Animation> */}
          </Card>
        </Container>
      </div>

      <div className={styles.container}>
        <svg
          viewBox='60 0 400 500'
          preserveAspectRatio='xMaxYMid'
          className={styles.svg}
        >
          <path
            d='M0, 100 C150, 200 350,
                0 500, 100 L500, 00 L0, 0 Z'
          />
        </svg>
      </div>

      <div className={styles.customContainer1}>
        <Container className={styles.innerCustomContainer}>
          <Card className={styles.customCard1}>
            {/* <Animation> */}
            <div style={{ zIndex: 1000 }}>
              <div className='row justify-content-center'>
                <div className='col-md-6  d-flex align-items-center'>
                  <Card.Body
                    className={[styles.customBody, styles.waveCardText].join(
                      ' '
                    )}
                  >
                    <Card.Title>
                      <Text
                        title
                        className={[styles.title, styles.zIndex].join(' ')}
                      >
                        <FormattedMessage id='index-page.featureSection.feature3.title' />
                      </Text>
                    </Card.Title>
                    <Card.Subtitle>
                      <Text
                        subtitle
                        className={[styles.subtitle, styles.zIndex].join(' ')}
                      >
                        <FormattedMessage id='index-page.featureSection.feature3.subTitle' />
                      </Text>
                    </Card.Subtitle>
                    <Card.Text>
                      <Text
                        body
                        className={[styles.text, styles.zIndex].join(' ')}
                      >
                        <FormattedMessage id='index-page.featureSection.feature3.text' />
                      </Text>
                    </Card.Text>
                    <Button
                      alternate
                      className={[styles.marginRight, styles.zIndex].join(' ')}
                      onClick={() => {
                        if (typeof window !== 'undefined') {
                          window.open(
                            `${COMPANY_URL}/request-demo?lang=${language}`,
                            '_blank'
                          );
                        }
                      }}
                    >
                      <FormattedMessage id='common.buttons.learnMore' />
                    </Button>
                  </Card.Body>
                </div>
                <div className='col-md-1' />

                <div className='col-md-5'>
                  <StaticImage
                    placeholder='blurred'
                    style={{ backgroundColor: 'transparent !important' }}
                    src='../../../images/branded_picture.png'
                    alt='campaign_management'
                  />
                </div>
              </div>
            </div>
            {/* </Animation> */}
          </Card>
          <Card className={styles.customCard1}>
            {/* <Animation fadeIn='fadeInRight'> */}
            <div className='row justify-content-center'>
              <div className={['col-md-5', styles.nextCardImg].join(' ')}>
                <StaticImage
                  placeholder='blurred'
                  style={{ backgroundColor: 'transparent !important' }}
                  src='../../../images/product_shipment.png'
                  alt='campaign_management'
                />
              </div>
              <div className='col-md-1' />

              <div className='col-md-6  d-flex align-items-center '>
                <Card.Body
                  className={[styles.customBody1, styles.textAlignRight].join(
                    ' '
                  )}
                >
                  <Card.Title>
                    <Text
                      title
                      className={[styles.title, styles.textAlignRight].join(
                        ' '
                      )}
                    >
                      <FormattedMessage id='index-page.featureSection.feature4.title' />
                    </Text>
                  </Card.Title>
                  <Card.Subtitle>
                    <Text
                      subtitle
                      className={[
                        styles.subtitle,
                        styles.textAlignRight,
                      ].join(' ')}
                    >
                      <FormattedMessage id='index-page.featureSection.feature4.subTitle' />
                    </Text>
                  </Card.Subtitle>
                  <Card.Text>
                    <Text
                      body
                      className={[styles.text, styles.textAlignRight].join(
                        ' '
                      )}
                    >
                      <FormattedMessage id='index-page.featureSection.feature4.text' />
                    </Text>
                  </Card.Text>
                  <Button
                    className={styles.marginRight}
                    onClick={() => {
                      if (typeof window !== 'undefined') {
                        window.open(
                          `${COMPANY_URL}/request-demo?lang=${language}`,
                          '_blank'
                        );
                      }
                    }}
                  >
                    <FormattedMessage id='common.buttons.learnMore' />
                  </Button>
                </Card.Body>
              </div>
            </div>
            {/* </Animation> */}
          </Card>
          <Card className={styles.customCard1}>
            {/* <Animation> */}
            <div className='row justify-content-center'>
              <div className='col-md-6  d-flex align-items-center'>
                <Card.Body className={styles.customBody1}>
                  <Card.Title>
                    <Text title className={styles.title}>
                      <FormattedMessage id='index-page.featureSection.feature5.title' />
                    </Text>
                  </Card.Title>
                  <Card.Subtitle>
                    <Text subtitle className={styles.subtitle}>
                      <FormattedMessage id='index-page.featureSection.feature5.subTitle' />
                    </Text>
                  </Card.Subtitle>
                  <Card.Text>
                    <Text body className={styles.text}>
                      <FormattedMessage id='index-page.featureSection.feature5.text' />
                    </Text>
                  </Card.Text>
                  <Button
                    className={styles.marginRight}
                    onClick={() => {
                      if (typeof window !== 'undefined') {
                        window.open(
                          `${COMPANY_URL}/request-demo?lang=${language}`,
                          '_blank'
                        );
                      }
                    }}
                  >
                    <FormattedMessage id='common.buttons.learnMore' />
                  </Button>
                </Card.Body>
              </div>
              <div className='col-md-1' />

              <div className='col-md-5'>
                <StaticImage
                  placeholder='blurred'
                  style={{ backgroundColor: 'transparent !important' }}
                  src='../../../images/reporting_and_tracking.png'
                  alt='campaign_management'
                />
              </div>
            </div>
            {/* </Animation> */}
          </Card>
          <Card className={styles.customCard1}>
            {/* <Animation fadeIn='fadeInRight'> */}
            <div className='row justify-content-center'>
              <div className='col-md-5'>
                <StaticImage
                  placeholder='blurred'
                  style={{ backgroundColor: 'transparent !important' }}
                  src='../../../images/payment_automation.png'
                  alt='campaign_management'
                />
              </div>
              <div className='col-md-1' />

              <div className='col-md-6  d-flex align-items-center'>
                <Card.Body
                  className={[styles.customBody1, styles.textAlignRight].join(
                    ' '
                  )}
                >
                  <Card.Title>
                    <Text
                      title
                      className={[styles.title, styles.textAlignRight].join(
                        ' '
                      )}
                    >
                      <FormattedMessage id='index-page.featureSection.feature6.title' />
                    </Text>
                  </Card.Title>
                  <Card.Subtitle>
                    <Text
                      subtitle
                      className={[
                        styles.subtitle,
                        styles.textAlignRight,
                      ].join(' ')}
                    >
                      <FormattedMessage id='index-page.featureSection.feature6.subTitle' />
                    </Text>
                  </Card.Subtitle>
                  <Card.Text>
                    <Text
                      body
                      className={[styles.text, styles.textAlignRight].join(
                        ' '
                      )}
                    >
                      <FormattedMessage id='index-page.featureSection.feature6.text' />
                    </Text>
                  </Card.Text>
                  <Button
                    className={styles.marginRight}
                    onClick={() => {
                      if (typeof window !== 'undefined') {
                        window.open(
                          `${COMPANY_URL}/request-demo?lang=${language}`,
                          '_blank'
                        );
                      }
                    }}
                  >
                    <FormattedMessage id='common.buttons.learnMore' />
                  </Button>
                </Card.Body>
              </div>
            </div>
            {/* </Animation> */}
          </Card>
        </Container>
      </div>
    </>
  );
};

FeatureSection.propTypes = {
  language: PropTypes.string.isRequired,
};

export default FeatureSection;

import React from "react";
import PropTypes from 'prop-types';

import * as styles from "./PrimaryButton.module.scss";

const PrimaryButton = ({ onClick = null, text = "", classNames }) => (
  <button
    type="button"
    onClick={onClick}
    className={[styles.primary_button, classNames].join(" ")}
  >
    {text}
  </button>
);

PrimaryButton.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  classNames: PropTypes.string.isRequired
};

export default PrimaryButton;
// extracted by mini-css-extract-plugin
export var cardsRow = "case-studies-section-module--cardsRow--fe277";
export var caseStudyCardButton = "case-studies-section-module--caseStudyCardButton--3548a";
export var caseStudyCardHoverHeading = "case-studies-section-module--caseStudyCardHoverHeading--2957b";
export var caseStudyCardHoverText = "case-studies-section-module--caseStudyCardHoverText--82cbe";
export var caseStudyCardImage = "case-studies-section-module--caseStudyCardImage--2ca63";
export var caseStudyCardImageHover = "case-studies-section-module--caseStudyCardImageHover--dbac5";
export var column = "case-studies-section-module--column--a81d4";
export var container = "case-studies-section-module--container--fd14c";
export var heading = "case-studies-section-module--heading--7aa5f";
export var mainHeading = "case-studies-section-module--mainHeading--d06dd";
export var mainRow = "case-studies-section-module--mainRow--f98d0";
export var showMoreRow = "case-studies-section-module--showMoreRow--a51e3";
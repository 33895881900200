import React from 'react';
import PropTypes from "prop-types"
import { Carousel } from 'react-bootstrap';
import * as styles from '../../../style/home-page/action-section.module.scss';

export const MobileSponserList = ({ sponsorList }) => (
  <Carousel indicators={false} interval={3000} align='center' controls={false}>
    {sponsorList.map((sponser) => (
      <Carousel.Item key={sponser.alt} className={styles.hAuto}>
        <div className={styles.hAuto}>
          <img
            style={{ width: "auto", height: 180 }}
            src={sponser.src}
            alt={sponser.alt}
            onMouseOver={(e) => {
              e.currentTarget.src = sponser.src;
            }}
            onFocus={(e) => {
              e.currentTarget.src = sponser.src;
            }}
          />
        </div>
      </Carousel.Item>
    ))}
  </Carousel>
);

MobileSponserList.propTypes = {
  sponsorList: PropTypes.arrayOf(PropTypes.shape({
    src: PropTypes.string,
    hoverSrc: PropTypes.string,
    alt: PropTypes.string,
  })).isRequired
}

export const DesktopSponserList = ({ sponsorList }) => (
  <>
    {sponsorList.map((sponser) => (
      <div key={sponser.alt} className={styles.desktopSponserImage}>
        <img
          style={{ height: 130, width: 'auto' }}
          src={sponser.src}
          alt={sponser.alt}
          onMouseOver={(e) => {
            e.currentTarget.src = sponser.hoverSrc;
          }}
          onFocus={(e) => {
            e.currentTarget.src = sponser.hoverSrc;
          }}
          onMouseOut={(e) => {
            e.currentTarget.src = sponser.src;
          }}
          onBlur={(e) => {
            e.currentTarget.src = sponser.src;
          }}
        />
      </div>
    ))}
  </>
);

DesktopSponserList.propTypes = {
  sponsorList: PropTypes.arrayOf(PropTypes.shape({
    src: PropTypes.string,
    hoverSrc: PropTypes.string,
    alt: PropTypes.string,
  })).isRequired
}

// extracted by mini-css-extract-plugin
export var buttonStyle = "feature-mobile-section-module--buttonStyle--cb357";
export var container = "feature-mobile-section-module--container--27cf4";
export var customBody = "feature-mobile-section-module--customBody--a7de6";
export var customBody1 = "feature-mobile-section-module--customBody1--11969";
export var customCard = "feature-mobile-section-module--customCard--c60ce";
export var customCard1 = "feature-mobile-section-module--customCard1--dd5be";
export var customContainer = "feature-mobile-section-module--customContainer--e1ea1";
export var customContainer1 = "feature-mobile-section-module--customContainer1--7b09b";
export var heading = "feature-mobile-section-module--heading--f4a3e";
export var innerCustomContainer = "feature-mobile-section-module--innerCustomContainer--338b2";
export var nextCard = "feature-mobile-section-module--nextCard--65ecf";
export var subtitle = "feature-mobile-section-module--subtitle--fd74a";
export var subtitle1 = "feature-mobile-section-module--subtitle1--ecbf0";
export var svg = "feature-mobile-section-module--svg--72391";
export var text = "feature-mobile-section-module--text--b9f05";
export var text1 = "feature-mobile-section-module--text1--ac149";
export var textAlignRight = "feature-mobile-section-module--textAlignRight--a2c40";
export var title = "feature-mobile-section-module--title--0c795";
export var title1 = "feature-mobile-section-module--title1--33f0c";
export var zIndex = "feature-mobile-section-module--zIndex--38cef";
import React from "react";
import { Card } from "react-bootstrap";
import { useIntl } from "gatsby-plugin-intl";
import PropTypes from 'prop-types';

import SecondaryButton from "../../lib/SecondaryButton-website-v2/SecondaryButton";
import * as styles from "../../style/home-page/sandwich-section.module.scss";
// import Animation from "../../lib/Animation/Animation";

const COMPANY_URL = process.env.GATSBY_COMPANY_URL;

const SandwichSection = ({ heading, para1, para2, buttonText }) => {
  const intl = useIntl();
  return (
  //  <Animation fadeIn="fadeInDown">
    <Card className={styles.customCard1}>
      <div className="row justify-content-center">
        <div className="col-md-12 d-flex align-items-center">
          <Card.Body className={styles.row}>
            <Card.Title className={styles.heading}>{heading}</Card.Title>
            <Card.Text className={styles.para}>{para1}</Card.Text>
            {para2 && <Card.Text className={styles.para}>{para2}</Card.Text>}
            <div className={styles.button}>
              <SecondaryButton
                text={buttonText}
                size="bg"
                onClick={() => {
                  if (typeof window !== "undefined") {
                    window.open(
                      `${COMPANY_URL}/request-demo?lang=${intl.locale}`,
                      "_blank"
                    );
                  }
                }}
              />
            </div>
          </Card.Body>
        </div>
      </div>
    </Card>
  //  </Animation>

  );
};

SandwichSection.propTypes = {
  heading: PropTypes.string.isRequired,
  para1: PropTypes.string.isRequired,
  para2: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
};

export default SandwichSection;

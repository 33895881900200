// extracted by mini-css-extract-plugin
export var container = "feature-section-module--container--d1080";
export var customBody = "feature-section-module--customBody--f1576";
export var customBody1 = "feature-section-module--customBody1--36820";
export var customCard = "feature-section-module--customCard--4b7d2";
export var customCard1 = "feature-section-module--customCard1--46268";
export var customContainer = "feature-section-module--customContainer--b25ef";
export var customContainer1 = "feature-section-module--customContainer1--6f4a0";
export var heading = "feature-section-module--heading--b8fe0";
export var innerCustomContainer = "feature-section-module--innerCustomContainer--f7e56";
export var marginRight = "feature-section-module--marginRight--ead92";
export var nextCardImg = "feature-section-module--nextCardImg--f3612";
export var subtitle = "feature-section-module--subtitle--0710f";
export var svg = "feature-section-module--svg--adff9";
export var text = "feature-section-module--text--ec3e5";
export var textAlignRight = "feature-section-module--textAlignRight--2523f";
export var title = "feature-section-module--title--dc248";
export var waveCardText = "feature-section-module--waveCardText--1dded";
export var zIndex = "feature-section-module--zIndex--770d3";
import React from 'react';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { MobileSponserList, DesktopSponserList } from './sponser-items';

import * as styles from '../../../style/home-page/action-section.module.scss';
import * as mobileStyle from '../../../style/home-page/sponsers.module.scss';

const uniliverGrey = require('../../../images/sponsors/unilever_grey.png')
const uniliverColor = require('../../../images/sponsors/unilever_color.png')

const planetaGrey = require('../../../images/sponsors/planeta_grey.png')
const planetaColor = require('../../../images/sponsors/planeta_color.png')

const platanoGrey = require('../../../images/sponsors/platano_grey.png')
const platanoColor = require('../../../images/sponsors/platano_color.png')

const elpozoGrey = require('../../../images/sponsors/elpozo_grey.png')
const elpozoColor = require('../../../images/sponsors/elpozo_color.png')

const vitaldentGrey = require('../../../images/sponsors/vitaldent_grey.png')
const vitaldentColor = require('../../../images/sponsors/vitaldent_color.png')

const Sponsers = () => {
  const breakpoints = useBreakpoint();

  const sponsorList = [
    {
      src: uniliverGrey.default,
      hoverSrc: uniliverColor.default,
      alt: 'unilever',
    },
    {
      src: planetaGrey.default,
      hoverSrc: planetaColor.default,
      alt: 'grupo_planeta',
    },
    {
      src: platanoGrey.default,
      hoverSrc: platanoColor.default,
      alt: 'platano de canarias',
    },
    {
      src: elpozoGrey.default,
      hoverSrc: elpozoColor.default,
      alt: 'elpozo',
    },
    {
      src: vitaldentGrey.default,
      hoverSrc: vitaldentColor.default,
      alt: 'vitaldent',
    },
  ];

  return breakpoints.md ? (
    <div className={mobileStyle.mobile_sponsers_container}>
      <MobileSponserList sponsorList={sponsorList} />
    </div>
  ) : (
    <div className={styles.brands}>
      <DesktopSponserList sponsorList={sponsorList} />
    </div>
  );
};

export default Sponsers;

import * as React from 'react';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { injectIntl } from 'gatsby-plugin-intl';
import PropTypes from 'prop-types';
import { PageProps } from 'gatsby';
import SEO from '../components/ui/seo';

import 'bootstrap/dist/css/bootstrap.css';
import '../style/style.scss';
import Layout from '../components/ui/layout';
import {
  ActionSection,
  SandwichSection,
  CaseStudiesSection,
  FeatureSection,
  Sponsers,
  HowItWorksSection,
  FeatureMobileSection
} from '../components/home-page';
import DefaultLanguage from '../hoc/default-language';
// import { CookiesWrapper } from '../components/cookies-modal/cookies-wrapper';

const IndexPage = ({ intl, location, pageContext }) => {
  const breakpoints = useBreakpoint();

  const learnMoreContent = {
    heading: `${intl.formatMessage({
      id: `index-page.sandwichSection.learnMoreContent.title`
    })}`,
    para1: `${intl.formatMessage({
      id: `index-page.sandwichSection.learnMoreContent.para1`
    })}`,
    para2: `${intl.formatMessage({
      id: `index-page.sandwichSection.learnMoreContent.para2`
    })}`,
    buttonText: `${intl.formatMessage({ id: `common.buttons.learnMore` })}`
  };

  const getStartedContent = {
    heading: `${intl.formatMessage({
      id: `index-page.sandwichSection.getStartedContent.title`
    })}`,
    para1: `${intl.formatMessage({
      id: `index-page.sandwichSection.getStartedContent.para1`
    })}`,
    buttonText: `${intl.formatMessage({ id: `common.buttons.getDemo` })}`
  };

  return (
    <>
      {/* <CookiesWrapper language={pageContext.language} /> */}
      <SEO page="index" language={pageContext.language} />
      <DefaultLanguage location={location}>
        <Layout location={location} language={pageContext.language} page="index">
          <ActionSection />
          <Sponsers />
          <SandwichSection
            heading={learnMoreContent.heading}
            para1={learnMoreContent.para1}
            para2={learnMoreContent.para2}
            buttonText={learnMoreContent.buttonText}
          />
          <HowItWorksSection language={pageContext.language} />

          {/* //TODO This should be one section */}
          {breakpoints.md ? (
            <FeatureMobileSection language={pageContext.language} />
          ) : (
            <FeatureSection language={pageContext.language} />
          )}
          <CaseStudiesSection />
          <SandwichSection
            heading={getStartedContent.heading}
            para1={getStartedContent.para1}
            buttonText={getStartedContent.buttonText}
          />
        </Layout>
      </DefaultLanguage>
    </>
  );
};

IndexPage.propTypes = {
  pageContext: PropTypes.instanceOf(PageProps).isRequired,
  location: PropTypes.instanceOf(PageProps).isRequired,
  intl: PropTypes.objectOf.isRequired
};

export default injectIntl(IndexPage);

import { navigate, useIntl } from 'gatsby-plugin-intl';
import React, { useState } from 'react';
import Text from '../../../lib/NewText/Text';
import * as styles from '../../../style/components/case-studies-section.module.scss';
import * as caseStudieStyles from '../../../style/components/case-studies.module.scss';
import * as caseStudieSectionStyles from '../../../style/home-page/case-studies-section.module.scss';
import CaseStudyCard from '../../ui/case-study-card';
// import Animation from '../../../lib/Animation/Animation';

const CaseStudiesContent = ({ data }) => {
  const [hoveredId, setHoveredId] = useState(null);
  const intl = useIntl();

  return data.map((caseStudy, i) => {
    const { id, caseStudies } = caseStudy;
    const backgroundImage = caseStudies.campaignCardBackground.sourceUrl;

    const hoverContent = (
      <>
        <Text
          subtitle
          className={`${caseStudieStyles.uppercase} ${caseStudieSectionStyles.caseStudyCardHoverHeading}`}
        >
          {intl.locale === 'en' ? caseStudies.titleEn : caseStudies.titleEs}
        </Text>
      </>
    );

    return (
      <div
        key={`${id}_${Math.random(i) * i}`}
        data-sal='slide-right'
        data-sal-delay={`${i}00`}
        className={`col-sm-6 col-md-4 col-lg-4 ${styles.padding}`}
      >
        <CaseStudyCard
          hoverActive={hoveredId === id}
          onClick={() => {
            navigate(`/case-studies/?${caseStudies.shareableLink}`);
          }}
          id={parseInt(i, 10)}
          onHover={() => setHoveredId(id)}
          onHoverOut={() => setHoveredId(null)}
          backgroundImage={backgroundImage}
          hoverContent={hoverContent}
          businessName={caseStudies.businessName}
          businessLogo={caseStudies.businessLogo.sourceUrl}
        />
      </div>
    );
  });
};

export default CaseStudiesContent;

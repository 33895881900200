import React from "react";
import PropTypes from 'prop-types';

import { Button } from "./SecondaryButtonStyle";

const SecondaryButton = ({
  text = "",
  size = "",
  classNames,
  onClick = null,
}) => (
  <Button type="button" onClick={onClick} className={classNames} size={size} >
    {text}
  </Button>
);

SecondaryButton.propTypes = {
  text: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  classNames: PropTypes.string.isRequired
};


export default SecondaryButton;

// extracted by mini-css-extract-plugin
export var action_heading = "action-section-module--action_heading--9b316";
export var action_para = "action-section-module--action_para--d9b44";
export var action_row = "action-section-module--action_row--960d4";
export var bigImage = "action-section-module--bigImage--9f165";
export var bodyText = "action-section-module--body-text--df2c6";
export var brands = "action-section-module--brands--2626e";
export var customCard1 = "action-section-module--customCard1--065e1";
export var desktopSponserImage = "action-section-module--desktopSponserImage--4bf31";
export var gatsbyImageWrapperConstrained = "action-section-module--gatsby-image-wrapper-constrained--d300a";
export var hAuto = "action-section-module--h-auto--4b95a";
export var titleMain = "action-section-module--title-main--dfd37";
import React from 'react';
import PropTypes from 'prop-types';
import * as styles from './Button.module.scss';

const Button = (props) => {
  const {children, className, white, alternate, onClick} = props;

  const classes = [styles.mainButton];
  switch (true) {
  case white:
    classes.push(styles.white);
    if (alternate) {
      classes.push(styles.whiteAlternate);
    }
    break;
  default:
    classes.push(styles.blue);
    if (alternate) {
      classes.push(styles.blueAlternate);
    }
    break;
  }

  if (className) {
    classes.push(className);
  }

  return (
    <button type="button" onClick={onClick || null} className={classes.join(' ')}>
      {children}
    </button>
  );
};

Button.propTypes = {
  children: PropTypes.string.isRequired,
  alternate: PropTypes.bool.isRequired,
  white: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string.isRequired,
};
export default Button;

import React from 'react';
import PropTypes from 'prop-types';

import * as styles from './Text.module.scss';

const Text = (props) => {

  const {children, className, title, body, subtitle} = props;
  const classes = [];

  switch (true) {
  case title:
    classes.push(styles.title);
    break;
  case subtitle:
    classes.push(styles.subtitle);
    break;
  case body:
    classes.push(styles.body);
    break;
  default:
    classes.push(styles.detail);
    break;
  }

  if (className) {
    classes.push(className);
  }

  return <div className={classes.join(' ')}>{children}</div>;
};

Text.propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  children: PropTypes.string.isRequired,

};

export default Text;

// import libraries
import React from 'react';
import { Card } from 'react-bootstrap';
import { FormattedMessage, injectIntl } from 'gatsby-plugin-intl';
import PropTypes from 'prop-types';
import { StaticImage } from 'gatsby-plugin-image';
// import Animation from '../../lib/Animation/Animation';

// import files
import SecondaryButton from '../../lib/SecondaryButton-website-v2/SecondaryButton';
import PrimaryButton from '../../lib/PrimaryButton-website-v2/PrimaryButton';
import * as styles from '../../style/home-page/action-section.module.scss';

const COMPANY_URL = process.env.GATSBY_COMPANY_URL;

const ActionSection = ({ intl }) => (
  <>
    <Card className={styles.customCard1}>
      <div
        className='row justify-content-center no-gutters'
        style={{
          maxWidth: '1140px',
          margin: 'auto',
        }}
      >
        <div className='col-lg-5 col-md-6 '>
          {/* <Animation> */}
          <Card.Body className={styles.action_row}>
            <Card.Title className={styles.action_heading}>
              <FormattedMessage id='index-page.actionSection.heading' />
            </Card.Title>
            <Card.Text className={styles.action_para}>
              <FormattedMessage id='index-page.actionSection.para1' />
            </Card.Text>
            <Card.Text className={styles.action_para}>
              <FormattedMessage id='index-page.actionSection.para2' />
            </Card.Text>
            <PrimaryButton
              text={`${intl.formatMessage({
                id: `common.buttons.getDemo`,
              })}`}
              onClick={() => {
                if (typeof window !== 'undefined') {
                  window.open(
                    `${COMPANY_URL}/request-demo?lang=${intl.locale}`,
                    '_blank'
                  );
                }
              }}
            />
            <SecondaryButton
              onClick={() => {
                if (typeof window !== 'undefined') {
                  window.open(`${COMPANY_URL}/request-demo?lang=${intl.locale}`, '_blank');
                }
              }}
              text={`${intl.formatMessage({
                id: `common.buttons.signUp`,
              })}`}
            />
          </Card.Body>
          {/* </Animation> */}
        </div>
        <div
          className='col-lg-7 col-md-6'
          style={{ display: 'flex', marginTop: '5px', justifyContent: 'center' }}
        >
          <StaticImage
            className={styles.bigImage}
            backgroundColor='transparent'
            placeholder='blurred'
            src='../../images/main_picture_no_background.png'
            alt='main_picture_no_background'
            style={{ maxHeight: 950 }}
          />
        </div>
      </div>
    </Card>
  </>
);

ActionSection.propTypes = {
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
    locale: PropTypes.string,

  }).isRequired
};

export default injectIntl(ActionSection);

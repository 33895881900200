import { FormattedMessage, navigate } from "gatsby-plugin-intl";
import React, { useEffect, useState } from "react";
import Button from "../../../lib/button/Button";
import Text from "../../../lib/Text/Text";
import { fetchAllCaseStudies } from "../../../services/case-studies";
import * as styles from "../../../style/home-page/case-studies-section.module.scss";
import CaseStudiesContent from "./case-studies-card";

const CaseStudiesSection = () => {
  const [caseStudies, setCaseStudies] = useState(false);

  const fetchCaseStudies = () => {
    fetchAllCaseStudies().then((response) => {
      if (response.data) {
        const { data } = response;

        const { nodes: allData } = data.caseStudies;

        const out = allData
          .sort((a, b) => {
            if (a.caseStudies.rank < b.caseStudies.rank) {
              return -1;
            }
            if (a.caseStudies.rank > b.caseStudies.rank) {
              return 1;
            }
            return 0;
          })
          .slice(0, 3);
        setCaseStudies(out);
      }
    });
  };

  useEffect(() => {
    fetchCaseStudies();
  }, []);

  return (
    <div className={styles.container}>
      <svg viewBox="0 0 500 500" preserveAspectRatio="xMinYMin meet">
        <path d="M0, 80 C300, 0 400, 200 500, 50 L500, 00 L0, 0 Z" />
      </svg>
      <div className={styles.column}>
        <div className={`${styles.mainRow} container`}>
          <Text className={styles.mainHeading} title>
            <FormattedMessage id="common.text.caseStudies" />
          </Text>
          <div className={["row", styles.cardsRow].join(" ")}>
            <CaseStudiesContent data={caseStudies || []} />
          </div>
          <div className={["row", styles.showMoreRow].join(" ")}>
            <Button
              alternate
              className={styles.caseStudyCardButton}
              onClick={() => {
                navigate(`/case-studies`);
              }}
            >
              <FormattedMessage id="common.buttons.showMore" />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CaseStudiesSection;

import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { Card, Container } from 'react-bootstrap';
import { FormattedMessage } from 'gatsby-plugin-intl';
import PropTypes from 'prop-types';
// import Animation from "../../../lib/Animation/Animation";

import * as styles from '../../../style/home-page/feature-mobile-section.module.scss';
import Text from '../../../lib/Text/Text';
import Button from '../../../lib/button/Button';

const COMPANY_URL = process.env.GATSBY_COMPANY_URL;

const FeatureMobileSection = (props) => {
  const { language = 'en' } = props;
  return (
    <>
      <div className={styles.customContainer}>
        <Container className={styles.innerCustomContainer}>
          <h5 className={styles.heading}>
            <FormattedMessage id="index-page.featureSection.heading" />
          </h5>

          <Card className={styles.customCard}>
            {/* <Animation> */}
            <div className="row justify-content-center">
              <div className="col-md-8 d-flex align-items-center">
                <Card.Body className={styles.customBody}>
                  <Card.Title>
                    <Text title className={styles.title}>
                      <FormattedMessage id="index-page.featureSection.feature1.title" />
                    </Text>
                  </Card.Title>
                </Card.Body>
              </div>
              <div className="col-md-6">
                <StaticImage
                  style={{ position: 'relative', left: '48px' }}
                  placeholder="blurred"
                  backgroundColor="transparent"
                  src="../../../images/influencer_finder.png"
                  alt="influencer_finder"
                />
              </div>
              <div className="col-md-8 d-flex align-items-center">
                <Card.Body className={styles.customBody}>
                  <Card.Subtitle>
                    <Text subtitle className={styles.subtitle}>
                      <FormattedMessage id="index-page.featureSection.feature1.subTitle" />{' '}
                    </Text>
                  </Card.Subtitle>
                  <Card.Text>
                    <Text body className={styles.text}>
                      <FormattedMessage id="index-page.featureSection.feature1.text" />
                    </Text>
                  </Card.Text>
                  <Button
                    alternate
                    className={styles.buttonStyle}
                    onClick={() => {
                      if (typeof window !== 'undefined') {
                        window.open(`${COMPANY_URL}/request-demo?lang=${language}`, '_blank');
                      }
                    }}>
                    <FormattedMessage id="common.buttons.learnMore" />
                  </Button>
                </Card.Body>
              </div>
            </div>
            {/* </Animation> */}
          </Card>

          <Card className={styles.customCard}>
            {/* <Animation fadeIn="fadeInRight"> */}
            <div className="row justify-content-center">
              <div className="col-md-12  d-flex align-items-center">
                <Card.Body className={[styles.customBody].join(' ')}>
                  <Card.Title>
                    <Text title className={[styles.title].join(' ')}>
                      <FormattedMessage id="index-page.featureSection.feature2.title" />
                    </Text>
                  </Card.Title>
                </Card.Body>
              </div>
              <div className="col-md-6">
                <StaticImage
                  placeholder="blurred"
                  backgroundColor="transparent"
                  src="../../../images/campaign_management.png"
                  alt="campaign_management"
                />
              </div>
              <div className="col-md-8 d-flex align-items-center">
                <Card.Body className={styles.customBody}>
                  <Card.Subtitle>
                    <Text subtitle className={[styles.subtitle].join(' ')}>
                      <FormattedMessage id="index-page.featureSection.feature2.subTitle" />
                    </Text>
                  </Card.Subtitle>
                  <Card.Text>
                    <Text body className={[styles.text].join(' ')}>
                      <FormattedMessage id="index-page.featureSection.feature2.text" />
                    </Text>
                  </Card.Text>
                  <Button
                    alternate
                    className={styles.buttonStyle}
                    onClick={() => {
                      if (typeof window !== 'undefined') {
                        window.open(`${COMPANY_URL}/request-demo?lang=${language}`, '_blank');
                      }
                    }}>
                    <FormattedMessage id="common.buttons.learnMore" />
                  </Button>
                </Card.Body>
              </div>
            </div>
            {/* </Animation> */}
          </Card>

          <Card className={styles.customCard}>
            {/* <Animation> */}
            <div className="row justify-content-center">
              <div className="col-md-6  d-flex align-items-center">
                <Card.Body className={styles.customBody}>
                  <Card.Title>
                    <Text title className={[styles.title, styles.zIndex].join(' ')}>
                      <FormattedMessage id="index-page.featureSection.feature3.title" />{' '}
                    </Text>
                  </Card.Title>
                </Card.Body>
              </div>
              <div className="col-md-12">
                <StaticImage
                  backgroundColor="transparent"
                  placeholder="blurred"
                  style={{ zIndex: '3' }}
                  src="../../../images/branded_picture.png"
                  alt="campaign_management"
                />
              </div>
              <div className="col-md-6 d-flex align-items-center">
                <Card.Body className={styles.customBody}>
                  <Card.Subtitle>
                    <Text subtitle className={[styles.subtitle, styles.zIndex].join(' ')}>
                      <FormattedMessage id="index-page.featureSection.feature3.subTitle" />
                    </Text>
                  </Card.Subtitle>
                  <Card.Text>
                    <Text body className={[styles.text, styles.zIndex].join(' ')}>
                      <FormattedMessage id="index-page.featureSection.feature3.text" />
                    </Text>
                  </Card.Text>
                  <Button
                    alternate
                    className={[styles.buttonStyle, styles.zIndex].join(' ')}
                    onClick={() => {
                      if (typeof window !== 'undefined') {
                        window.open(`${COMPANY_URL}/request-demo?lang=${language}`, '_blank');
                      }
                    }}>
                    <FormattedMessage id="common.buttons.learnMore" />
                  </Button>
                </Card.Body>
              </div>
            </div>
            {/* </Animation> */}
          </Card>
        </Container>
      </div>

      <div className={styles.container}>
        <svg viewBox="0 0 500 500" preserveAspectRatio="xMaxYMid" className={styles.svg}>
          <path
            d="M0, 100 C150, 200 350,
                0 500, 100 L500, 00 L0, 0 Z"
          />
        </svg>
      </div>

      <div className={styles.customContainer1}>
        <Container className={styles.innerCustomContainer}>
          <Card className={[styles.customCard1, styles.nextCard].join(' ')}>
            {/* <Animation fadeIn="fadeInRight"> */}
            <div className="row justify-content-center  pt-5">
              <div className="col-md-6  d-flex align-items-center  pt-5">
                <Card.Body className={[styles.customBody1].join(' ')}>
                  <Card.Title className="pt-2">
                    <Text title className={[styles.title1].join(' ')}>
                      <FormattedMessage id="index-page.featureSection.feature4.title" />{' '}
                    </Text>
                  </Card.Title>
                </Card.Body>
              </div>
              <div className="col-md-12">
                <StaticImage
                  backgroundColor="transparent"
                  placeholder="blurred"
                  src="../../../images/product_shipment.png"
                  alt="campaign_management"
                />
              </div>
              <div className="col-md-6 d-flex align-items-center">
                <Card.Body className={styles.customBody1}>
                  <Card.Subtitle>
                    <Text subtitle className={[styles.subtitle1].join(' ')}>
                      <FormattedMessage id="index-page.featureSection.feature4.subTitle" />{' '}
                    </Text>
                  </Card.Subtitle>
                  <Card.Text>
                    <Text body className={[styles.text1].join(' ')}>
                      <FormattedMessage id="index-page.featureSection.feature4.text" />
                    </Text>
                  </Card.Text>
                  <Button
                    className={styles.buttonStyle}
                    onClick={() => {
                      if (typeof window !== 'undefined') {
                        window.open(`${COMPANY_URL}/request-demo?lang=${language}`, '_blank');
                      }
                    }}>
                    <FormattedMessage id="common.buttons.learnMore" />
                  </Button>
                </Card.Body>
              </div>
            </div>
            {/* </Animation> */}
          </Card>
          <Card className={styles.customCard1}>
            {/* <Animation> */}
            <div className="row justify-content-center">
              <div className="col-md-6  d-flex align-items-center">
                <Card.Body className={styles.customBody1}>
                  <Card.Title>
                    <Text title className={styles.title1}>
                      <FormattedMessage id="index-page.featureSection.feature5.title" />{' '}
                    </Text>
                  </Card.Title>
                </Card.Body>
              </div>
              <div className="col-md-12">
                <StaticImage
                  backgroundColor="transparent"
                  placeholder="blurred"
                  src="../../../images/reporting_and_tracking.png"
                  alt="campaign_management"
                />
              </div>
              <div className="col-md-6 d-flex align-items-center">
                <Card.Body className={styles.customBody1}>
                  <Card.Subtitle>
                    <Text subtitle className={styles.subtitle1}>
                      <FormattedMessage id="index-page.featureSection.feature5.subTitle" />{' '}
                    </Text>
                  </Card.Subtitle>
                  <Card.Text>
                    <Text body className={styles.text1}>
                      <FormattedMessage id="index-page.featureSection.feature5.text" />
                    </Text>
                  </Card.Text>
                  <Button
                    className={styles.buttonStyle}
                    onClick={() => {
                      if (typeof window !== 'undefined') {
                        window.open(`${COMPANY_URL}/request-demo?lang=${language}`, '_blank');
                      }
                    }}>
                    <FormattedMessage id="common.buttons.learnMore" />
                  </Button>
                </Card.Body>
              </div>
            </div>
            {/* </Animation> */}
          </Card>
          <Card className={styles.customCard1}>
            {/* <Animation fadeIn="fadeInRight"> */}
            <div className="row justify-content-center">
              <div className="col-md-6  d-flex align-items-center">
                <Card.Body className={styles.customBody1}>
                  <Card.Title>
                    <Text title className={styles.title1}>
                      <FormattedMessage id="index-page.featureSection.feature6.title" />{' '}
                    </Text>
                  </Card.Title>
                </Card.Body>
              </div>
              <div className="col-md-12">
                <StaticImage
                  backgroundColor="transparent"
                  placeholder="blurred"
                  src="../../../images/payment_automation.png"
                  alt="campaign_management"
                />
              </div>
              <div className="col-md-6 d-flex align-items-center">
                <Card.Body className={styles.customBody1}>
                  <Card.Subtitle>
                    <Text subtitle className={[styles.subtitle1].join(' ')}>
                      <FormattedMessage id="index-page.featureSection.feature6.subTitle" />
                    </Text>
                  </Card.Subtitle>
                  <Card.Text>
                    <Text body className={[styles.text1].join(' ')}>
                      <FormattedMessage id="index-page.featureSection.feature6.text" />
                    </Text>
                  </Card.Text>
                  <Button
                    className={styles.buttonStyle}
                    onClick={() => {
                      if (typeof window !== 'undefined') {
                        window.open(`${COMPANY_URL}/request-demo?lang=${language}`, '_blank');
                      }
                    }}>
                    <FormattedMessage id="common.buttons.learnMore" />
                  </Button>
                </Card.Body>
              </div>
            </div>
            {/* </Animation> */}
          </Card>
        </Container>
      </div>
    </>
  );
};

FeatureMobileSection.propTypes = {
  language: PropTypes.string.isRequired
};

export default FeatureMobileSection;

import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

export default [
  {
    eventKey: '0',
    headingBold: 'index-page.howItWorksSections.AccordianOne.headingBold',
    heading: 'index-page.howItWorksSections.AccordianOne.heading',
    pointOne: 'index-page.howItWorksSections.AccordianOne.pointOne',
    pointTwo: 'index-page.howItWorksSections.AccordianOne.pointTwo',
    pointThree: 'index-page.howItWorksSections.AccordianOne.pointThree',
    'image-en': () => (
      <StaticImage
        style={{ width: "110%", position: " relative", left: "-18px " }}
        alt='launch your campaign'
        placeholder='blurred'
        src='../../../images/how-it-works/launch_en.png'
      />
    ),
    'image-es': () => (
      <StaticImage
        style={{ width: "110%", position: " relative", left: "-18px " }}
        alt='launch your campaign'
        placeholder='blurred'
        src='../../../images/how-it-works/launch_es.png'
      />
    ),
  },
  {
    eventKey: '1',
    headingBold: 'index-page.howItWorksSections.AccordianTwo.headingBold',
    heading: 'index-page.howItWorksSections.AccordianTwo.heading',
    pointOne: 'index-page.howItWorksSections.AccordianTwo.pointOne',
    pointTwo: 'index-page.howItWorksSections.AccordianTwo.pointTwo',
    pointThree: 'index-page.howItWorksSections.AccordianTwo.pointThree',
    'image-en': () => (
      <StaticImage
        style={{ width: "110%", position: " relative", left: "-18px " }}
        alt='approve expression of interest'
        placeholder='blurred'
        src='../../../images/how-it-works/approve_en.png'
      />
    ),
    'image-es': () => (
      <StaticImage
        style={{ width: "110%", position: " relative", left: "-18px " }}
        alt='approve expression of interest'
        placeholder='blurred'
        src='../../../images/how-it-works/approve_es.png'
      />
    ),
  },
  {
    eventKey: '2',
    headingBold: 'index-page.howItWorksSections.AccordianThree.headingBold',
    heading: 'index-page.howItWorksSections.AccordianThree.heading',
    pointOne: 'index-page.howItWorksSections.AccordianThree.pointOne',
    pointTwo: 'index-page.howItWorksSections.AccordianThree.pointTwo',
    pointThree: 'index-page.howItWorksSections.AccordianThree.pointThree',
    'image-en': () => (
      <StaticImage
        style={{ width: "110%", position: " relative", left: "-18px " }}
        alt='manage delivery'
        placeholder='blurred'
        src='../../../images/how-it-works/manage_en.png'
      />
    ),
    'image-es': () => (
      <StaticImage
        style={{ width: "110%", position: " relative", left: "-18px " }}
        alt='manage delivery'
        placeholder='blurred'
        src='../../../images/how-it-works/manage_es.png'
      />
    ),
  },
  {
    eventKey: '3',
    headingBold: 'index-page.howItWorksSections.AccordianFour.headingBold',
    heading: 'index-page.howItWorksSections.AccordianFour.heading',
    pointOne: 'index-page.howItWorksSections.AccordianFour.pointOne',
    pointTwo: 'index-page.howItWorksSections.AccordianFour.pointTwo',
    pointThree: 'index-page.howItWorksSections.AccordianFour.pointThree',
    'image-en': () => (
      <StaticImage
        style={{ width: "110%", position: " relative", left: "-18px " }}
        alt='control the final content'
        placeholder='blurred'
        src='../../../images/how-it-works/control_en.png'
      />
    ),
    'image-es': () => (
      <StaticImage
        style={{ width: "110%", position: " relative", left: "-18px " }}
        alt='control the final content'
        placeholder='blurred'
        src='../../../images/how-it-works/control_es.png'
      />
    ),
  },
  {
    eventKey: '4',
    headingBold: 'index-page.howItWorksSections.AccordianFive.headingBold',
    heading: 'index-page.howItWorksSections.AccordianFive.heading',
    pointOne: 'index-page.howItWorksSections.AccordianFive.pointOne',
    pointTwo: 'index-page.howItWorksSections.AccordianFive.pointTwo',
    pointThree: 'index-page.howItWorksSections.AccordianFive.pointThree',
    'image-en': () => (
      <StaticImage
        style={{ width: "110%", position: " relative", left: "-55px " }}
        alt='measure your success'
        placeholder='blurred'
        src='../../../images/how-it-works/measure_en.png'
      />
    ),
    'image-es': () => (
      <StaticImage
        style={{ width: "110%", position: " relative", left: "-55px " }}
        alt='measure your success'
        placeholder='blurred'
        src='../../../images/how-it-works/measure_es.png'
      />
    ),
  },
];

import PropTypes from "prop-types";
import React, { useState } from "react";
import { FormattedMessage } from "gatsby-plugin-intl";
import { Accordion, Card, Button } from "react-bootstrap";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import classnames from "classnames";

import CustomText from "../../../lib/NewText/Text";

import data from "./how-it-works.data";
import "../../../style/home-page/how-it-works.scss";

const arrowUp = require("../../../images/up-arrow.png");
const arrowDown = require("../../../images/down-arrow.png");

const HowItWorksSection = ({ language }) => {
  const breakpoints = useBreakpoint();
  const [stage, setStage] = useState("0");
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="how-it-works-section">
      <div className="container">
        <div className="row">
          <div className="col-md accordian-container">
            <CustomText className="title" title>
              <FormattedMessage id="index-page.howItWorksSections.heading" />
            </CustomText>
            <Accordion className="accordian" defaultActiveKey>
              {data.map((item, index) => (
                <>
                  <Card>
                    <Card.Header className="header">
                      <Accordion.Toggle
                        as={Button}
                        variant="link"
                        eventKey={item.eventKey}
                        onClick={() => {
                          if (stage === item.eventKey) {
                            setIsOpen(!isOpen);
                          } else {
                            setIsOpen(true);
                          }
                          setStage(item.eventKey);
                        }}
                      >
                        <div className="header-container">
                          <div>
                            {stage === item.eventKey ? (
                              <span className={classnames({ opened: isOpen })}>
                                <span className="extra-bold">
                                  <FormattedMessage id={item.headingBold} />{" "}
                                </span>{" "}
                                <FormattedMessage id={item.heading} />
                              </span>
                            ) : (
                              <>
                                <span className="extra-bold">
                                  <FormattedMessage id={item.headingBold} />
                                </span>{" "}
                                <FormattedMessage id={item.heading} />
                              </>
                            )}
                          </div>
                          {isOpen && stage === item.eventKey ? (
                            <img
                              alt="arrow-up"
                              height={24}
                              src={arrowUp.default}
                            />
                          ) : (
                            <img
                              alt="arrow-down"
                              height={24}
                              src={arrowDown.default}
                            />
                          )}
                        </div>
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey={item.eventKey}>
                      <>
                        <Card.Body>
                          <ul>
                            <li className="points">
                              <FormattedMessage id={item.pointOne} />
                            </li>
                            <li className="points">
                              <FormattedMessage id={item.pointTwo} />
                            </li>
                            <li className="points">
                              <FormattedMessage id={item.pointThree} />
                            </li>
                          </ul>
                        </Card.Body>

                        {breakpoints.md && data[stage][`image-${language}`]()}
                      </>
                    </Accordion.Collapse>
                  </Card>

                  {index !== data.length - 1 && <div className="divider" />}
                </>
              ))}
            </Accordion>
          </div>
          <div className="col-md image-container">
            {/* 
              Sorry for the below code :P 
              ===========================
              It gets the correct object in the array based on the 'stage'
              stored in the state. After that it selects the 'en' or 'es'
              image component in the object.
            */}
            {!breakpoints.md && data[stage][`image-${language}`]()}
          </div>
        </div>
      </div>
    </div>
  );
};

HowItWorksSection.propTypes = {
  language: PropTypes.string.isRequired,
};

export default HowItWorksSection;

import styled, { css } from "styled-components";

export const Button = styled.button`
box-sizing: border-box;
height: 40px;
width: 176px;
border: 3px solid #ffffff;
background-color: #ffffff;
box-shadow: -3px 4px 4px rgba(0, 0, 0, 0.2);
color: #3f5c83;
font-family: "Montserrat Medium";
text-align: center;
border-radius: 10px;
// margin-bottom: 14px;
font-size: 16px;
z-index: 1;
font-weight: 500;
position: relative;
@media (min-width: 863px) and (max-width: 1199px) {
  font-size: 14px;
  width: 140px;
}
@media (min-width: 768px) and (max-width: 862px) {
  font-size: 9px;
  width: 110px;
}
@media (max-width: 600px) {
  margin-right: 0px;
  width: 100%;
}
}


  &:hover {
    color: #3f5c83;
    border: 3px solid #edebed;
    background-color: #edebed;
  }

${(props) =>
    props.size === "bg" &&
  css`
    height: 41px;
    width: 228px;
    border-radius: 9px;
    box-shadow: -3px 3px 8px rgb(0 0 0 / 20%);
    @media (min-width: 1610px) and (max-width: 2559px) {
      height: 46px;
      width: 290px;
      font-size: 19px;
    }
  `}
`;
